<template>
  <el-dialog
      :visible.sync="visible"
      append-to-body
      custom-class="tag-dialog"
      :show-close="false"
      width="594px"
      center
  >
    <div class="title">注册成功，欢迎来到工程师小威</div>
    <div class="desc">选择你感兴趣的让小威更了解你</div>
    <div class="tags">
      <div class="tag-item" v-for="val in 20" :key="val" :class="{'tag-item-selected' : val == 3}">标签1</div>
    </div>
    <div class="confirm-btn" :class="{'confirm-btn-disable' : tags.length == 0}">选好了</div>
  </el-dialog>
</template>

<script>
export default {
  name: "Tag",
  data() {
    return {
      visible: false,
      tags: []
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .tag-dialog {
  border-radius: 28px;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 42px;
    .title {
      font-size: 28px;
      font-family: PingFangSC;
      font-weight: 600;
      color: rgba(0,0,0,0.88);
      line-height: 40px;
      margin-bottom: 8px;
    }
    .desc {
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: rgba(0,0,0,0.88);
      line-height: 24px;
      margin-bottom: 36px;
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      max-height: 250px;
      overflow-y: scroll;
      .tag-item {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 112px;
        height: 52px;
        background: #FFFFFF;
        border-radius: 26px;
        border: 1px solid #D6D6D6;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: rgba(0,0,0,0.88);
        margin-right: 14px;
        margin-bottom: 14px;
      }
      .tag-item:hover {
        border-color: #1777FF;
      }
      .tag-item-selected {
        background: #1777FF;
        color: #FFFFFF;
        border-color: #1777FF;
      }
      .tag-item:nth-child(4n) {
        margin-right: 0;
      }
    }
    .tags::-webkit-scrollbar {
      width: 6px;
      border-radius: 3px;
    }
    .tags::-webkit-scrollbar-track {
      background-color: #E4E4E4;
    }
    .tags::-webkit-scrollbar-thumb {
      background-color: #1777FF;
    }
    .tags::-webkit-scrollbar-thumb:hover {
      background-color: #1777FF;
    }
    .confirm-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 376px;
      height: 48px;
      background: #1777FF;
      border-radius: 24px;
      margin-top: 38px;font-size: 16px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
    }
    .confirm-btn-disable {
      background: rgba(23, 119, 255, 0.5);
    }
  }
}
</style>
