/**
 * 是否手机端
 * */
export function isMobile() {
  const userAgentInfo = navigator.userAgent;
  const mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
  let flag = false;
  for (let v = 0; v < mobileAgents.length; v++) {
    if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
      flag = true;
      break;
    }
  }
  return flag;
}
/**
 * 是否登陆
 * */
export function isLogin() {
  const token = localStorage.getItem('token')
  if (token) {
    return true
  }
  return false;
}

/**
 * 校验手机号
 * */
export function validatePhone(variable) {
  if (!variable) {
    return false;
  }
  const phoneRegExp = /^1[23456789]\d{9}$/;
  return phoneRegExp.test(variable);
}
