<template>
  <el-dialog
      :visible.sync="visible"
      append-to-body
      custom-class="protocol-dialog"
      :show-close="false"
      width="891px"
      center
  >
    <div class="title">感谢您对我们的关注</div>
    <div class="desc">
      使用前请先阅读并同意以下内容
    </div>
    <div class="content">
      服务协议
      生效日期：2023年9月13日
      更新日期：2023年9月6日
      欢迎您体验通义千问服务！
      【审慎阅读】您在同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款的内容，特别是免除或者限制责任的条款、法律适用和争议解决条款，这些条款将以粗体或粗体下划线标识，您应重点阅读。如您对协议有任何疑问，可以通过本协议披露的联系方式与我们沟通。
      【签约动作】当您阅读并点击同意本协议或以其他方式选择接受本协议后，即表示您已充分阅读、理解并接受本协议的全部内容，并与我们达成一致。本协议自您通过网络页面点击确认或以其他方式选择接受本协议之日起成立。阅读本协议的过程中，如果您不同意本协议或

      【审慎阅读】您在同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款的内容，特别是免除或者限制责任的条款、法律适用和争议解决条款，这些条款将以粗体或粗体下划线标识，您应重点阅读。如您对协议有任何疑问，可以通过本协议披露的联系方式与我们沟通。
      【签约动作】当您阅读并点击同意本协议或以其他方式选择接受本协议后，即表示您已充分阅读、理解并接受本协议的全部内容，并与我们达成一致。本协议自您通过网络页面点击确认或以其他方式选择接受本协议之日起成立。阅读本协议的过程中，如果您不同意本协议或
    </div>
    <div class="btns">
      <div class="cancel-btn" @click="visible = false">取消</div>
      <div class="confirm-btn" @click="visible = false">同意</div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "protocol",
  data() {
    return {
      visible: false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .protocol-dialog {
  border-radius: 28px;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 50px 36px;
    .title {
      font-size: 28px;
      font-family: PingFangSC;
      font-weight: 600;
      color: rgba(0,0,0,0.88);
      line-height: 40px;
      margin-bottom: 4px;
    }
    .desc {
      font-size: 28px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: rgba(0,0,0,0.88);
      line-height: 40px;
      margin-bottom: 41px;
    }
    .content {
      max-height:518px;
      background: #FAFAFA;
      overflow-y: scroll;
      padding: 26px 36px;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: rgba(0,0,0,0.85);
      line-height: 24px;
    }
    .content::-webkit-scrollbar {
      width: 6px;
    }
    .content::-webkit-scrollbar-track {
      background-color: #E4E4E4;
    }
    .content::-webkit-scrollbar-thumb {
      background-color: #1777FF;
    }
    .content::-webkit-scrollbar-thumb:hover {
      background-color: #1777FF;
    }
    .btns {
      display: flex;
      justify-content: center;
      margin-top: 34px;
      .cancel-btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 144px;
        height: 48px;
        background: #FFFFFF;
        border-radius: 24px;
        border: 1px solid #1777FF;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #1777FF;
      }
      .confirm-btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 144px;
        height: 48px;
        background: #1777FF;
        border-radius: 24px;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        margin-left: 42px;
      }
    }
  }
}
</style>
