import Vue from 'vue'
import VueRouter from 'vue-router'
import { isMobile } from "@/utils";
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/detail.vue'),
    meta: {
      requiresDesktop:true,
      requireAuth: true
    }
  },
  {
    path: '/appDetail',
    name: 'appDetail',
    component: () => import('../views/appDetail'),
    meta: {
      requiresMobile: true,
      requireAuth: true
    }
  },
  {
    path: '/appLogin',
    name: 'appLogin',
    component: () => import('../views/appLogin')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/agreement')
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import('../views/privacyPolicy')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresMobile) && !isMobile()) {
    next('/detail')
  } else if (to.matched.some(record => record.meta.requiresDesktop) && isMobile()) {
    next('/appDetail')
  } else {
    next() // 否则继续正常导航
  }
})
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requireAuth)) {
//     if (/* 检查用户是否已登录的逻辑 */) {
//       next() // 用户已登录，继续访问
//     } else {
//       next({ name: 'login', query: { redirect: to.fullPath } }) // 用户未登录，重定向到登录页面
//     }
//   } else {
//     next() // 对于不需要登录的路由，直接放行
//   }
// })


export default router
