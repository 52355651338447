<template>
  <div class="home-page" :class="{'app' : isMobile}">
    <div class="container">
      <div class="header-container">
        <div class="header-main">
          <img src="../assets/images/logo.png">
          <div class="use-btn" @click="toExperience()">免费使用</div>
        </div>
      </div>
      <div class="container-main">
        <div class="title-wrap">
          <div class="title">
            <img src="../assets/images/logo_icon.png">
            <span>工程师小威</span>
          </div>
          <div class="title-info">汽车人的智能大脑</div>
          <div class="info">基于汽车行业大模型的Al助理，成为工程师们可信赖的伙伴。</div>
        </div>
        <div class="btn" @click="toExperience()"></div>
      </div>
    </div>
    <div class="footer-container">
      <div class="footer-info">
        <div class="agreement">
          <div class="service-btn" @click="toAgreement()">服务协议</div>
          <div class="privite-btn" @click="toPrivacyPolicy()">隐私协议</div>
        </div>
        <div class="code">备案号码：网信算备 330110507206401230035号</div>
      </div>
    </div>
    <login ref="login" />
    <tag ref="tag" />
  </div>
</template>

<script>
import Login from '@/components/login';
import Tag from "@/components/Tag";
import { isMobile, isLogin } from "@/utils";
export default {
  name: 'Home',
  components: {
    Login,
    Tag
  },
  data() {
    return {
      isMobile: isMobile()
    }
  },
  methods: {
    /**
     * 立即体验
     * */
    toExperience() {
      // if (isLogin()) {
        this.$router.push({
          path: '/detail'
        })
      // } else {
      //   if (this.isMobile) {
      //     this.$router.push({
      //       path: '/appLogin'
      //     })
      //   } else {
      //     this.$refs.login.visible = true;
      //   }
      // }
    },
    /**
     * 用户协议
     * */
    toAgreement() {
      if (this.isMobile) {
        this.$router.push({
          path: '/agreement'
        })
      } else {
        window.open(`${location.origin}/agreement`)
      }
    },
    /**
     * 隐私政策
     * */
    toPrivacyPolicy() {
      if (this.isMobile) {
        this.$router.push({
          path: '/privacyPolicy'
        })
      } else {
        window.open(`${location.origin}/privacyPolicy`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home-page {
  display: flex;
  flex-direction: column;
  width: 100vw;
  background: #fff;
  min-height: 100vh;
  overflow-y: auto;

  .container {
    width: 100%;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    background: url("../assets/images/home_bg.png") no-repeat;
    background-size: cover;
    .header-container {
      width: 100%;
      height: 64px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      .header-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 1200px;
        .use-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 122px;
          height: 36px;
          background: #FFFFFF;
          border-radius: 20px;
          border: 1px solid #D6D6D6;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: rgba(0,0,0,0.88);
          cursor: pointer;
        }
        .use-btn:hover {
          background: #1777FF;
          border-color: #1777FF;
          color: #fff;
        }
      }
    }
    .container-main {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 117px 0 68px;
      .title-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 30px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: rgba(0,0,0,0.88);
          margin-bottom: 12px;
          span {
            margin-left: 12px;
          }
        }
        .title-info {
          font-size: 48px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: rgba(0,0,0,0.88);
          margin-bottom: 12px;
        }
        .info {
          font-size: 22px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: rgba(0,0,0,0.45);
        }
      }
      .btn {
        margin-top: 71px;
        width: 220px;
        height: 56px;
        background: url("../assets/images/home_btn.png") no-repeat;
        cursor: pointer;
      }
    }
  }
  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    overflow: hidden;
    padding: 38px 0;
    background: rgba(0,0,0,0.88);
    font-size: 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: rgba(255,255,255,0.88);
    .footer-detail {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 14px;
    }
    .footer-info {
      display: flex;
      .agreement {
        display: flex;
        .service-btn {
          cursor: pointer;
          margin-right: 31px;
        }
        .privite-btn {
          cursor: pointer;
          margin-right: 32px;
        }
      }
    }
  }
}
.app {
  .container {
    .header-container {
      .header-main {
        padding: 0 0.2rem;
        .use-btn {
          display: none;
        }
      }
    }
    .container-main {
      padding-left: 0.35rem;
      padding-right: 0.35rem;
      .title-wrap {
        .title-info {
          font-size: 0.96rem;
        }
        .info {
          font-size: 0.38rem;
          text-align: center;
          line-height: 0.69rem;
        }
      }
    }
  }
  .footer-container {
    display: flex;
    align-items: center;
    height: 1.5rem;
    .footer-info {
      display: flex;
      flex-direction: column;
      .agreement {
        justify-content: center;
        margin-bottom: 0.2rem;
      }
    }
  }
}
</style>
